@import 'variables';

.eco-financing-color {
    background: -webkit-linear-gradient(
        $fallback-header-background-color,
        $financing-color
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.utility-tile-button {
    background-color: unset;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 100%;

    .utility-logo {
        max-width: 100%;
        max-height: 100%;
        flex-shrink: 0;
    }
}

.receipt-container {
    border: 2px dotted #cbcbcb;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 100%;

    .receipt-image-list .bill-image {
        height: 100px;
        padding: 10px;
        color: #fff;
        background-size: cover;
        background-position: 50%;
        position: relative;
        overflow: hidden;
        font-size: 14px;
        border-radius: 4px 4px 4px 4px;

        .bill-image-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    .bill-image-name {
        width: 250px;
        margin-top: 15px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (min-width: 992px) {
            width: 300px;
        }
    }
}
