@import 'variables';

button.breadcrumb-item.btn.btn-link:not(:disabled):not(.active) {
    text-decoration: underline;
}

button.breadcrumb-item.btn.btn-link:disabled {
    opacity: 1;
}

button.breadcrumb-item.btn.btn-link {
    color: $global-body-color;
}
