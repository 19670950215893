html {
    font-size: 16px; // define base for 1 rem
}

body {
    &.menu-is-open,
    &.search-is-open {
        overflow: hidden;
    }
}

.min-w-0 {
    min-width: 0;
}

.min-w-100 {
    min-width: 100%;
}

.min-h-100 {
    min-height: 100%;
}

.max-w-100 {
    max-width: 100%;
}

.max-h-100 {
    max-height: 100%;
}

.w-15 {
    width: 15%;
}

.w-10 {
    width: 10%;
}

// Create a variation of the `.container-*` class with a fixed width
.conatainer-fixed,
.container-fixed-sm,
.container-fixed-md,
.container-fixed-lg,
.container-fixed-xl,
.container-fixed-xxl {
    width: 100%;
    padding-left: $container-padding-x;
    padding-right: $container-padding-x;
    margin-left: auto;
    margin-right: auto;
}

.container-fixed,
.container-fixed-sm {
    max-width: map-get($container-max-widths, sm);
}

.container-fixed-md {
    max-width: map-get($container-max-widths, md);
}

.container-fixed-lg {
    max-width: map-get($container-max-widths, slg);
}

.container-fixed-xl {
    max-width: map-get($container-max-widths, xl);
}

.container-fixed-xxl {
    max-width: map-get($container-max-widths, xxl);
}

.page-wrapper {
    // Ensure that all page content fills the available space
    & > router-outlet + * {
        flex-grow: 1;
        flex-shrink: 0;
    }
}

// Comparison tray
.comparison-tray-open {
    position: relative;

    .main {
        padding-bottom: $comparison-tray-size;
    }
}

// Call to action
.has-call-to-action {
    // Ensure a call-to-action button can take two lines of text without resizing
    // the container
    .call-to-action {
        .btn {
            height: 56px; // equal to a btn with py-3
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    // Apply enough padding to the footer to ensure it can scroll above it
    & + site-footer {
        padding-bottom: $call-to-action-size;

        @media (min-width: map-get($grid-breakpoints, lg)) {
            padding-bottom: 0;
        }
    }

    // If a page has both a call-to-action and comparison tray
    comparison-tray {
        bottom: $call-to-action-size;

        &.position-absolute {
            bottom: 0;
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
            bottom: 0;
        }
    }
}

// Row/column structure that looks like a table
.row-table {
    &.row > .col {
        @media (min-width: map-get($grid-breakpoints, sm)) {
            &:first-child,
            &:nth-child(2) {
                border-top: $border-width solid $border-color;
            }

            &:nth-child(odd) {
                border-right: $border-width solid $border-color;
            }
        }
    }
}
