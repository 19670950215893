/**
 * Required Bootstrap imports (RBi) are imported in the variables file below
 * Files include: Bootstrap functions, variables, mixins, utilities
 */
@import 'variables';

/**
 * NOTE from Bootstrap docs:
 * To remove variables from any map, use map-remove.
 * Be aware you must insert it between RBi and
 * "Optional Bootstrap imports"
 */
@import 'map-remove';

/**
 * Optional Bootstrap imports (OBi)
 * Full list copied from the Bootstrap docs for easy access.
 * Uncomment only the files we require.
 */

/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap';

// Layout & components
// @import "node_modules/bootstrap/scss/root";
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
// @import "node_modules/bootstrap/scss/images";
@import 'node_modules/bootstrap/scss/containers';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
// @import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/button-group";
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
// @import "node_modules/bootstrap/scss/card";
@import 'node_modules/bootstrap/scss/accordion';
@import 'node_modules/bootstrap/scss/breadcrumb';
@import 'node_modules/bootstrap/scss/pagination';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/alert';
// @import "node_modules/bootstrap/scss/progress";
@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
// @import "node_modules/bootstrap/scss/toasts";
@import 'node_modules/bootstrap/scss/modal';
// @import "node_modules/bootstrap/scss/tooltip";
@import 'node_modules/bootstrap/scss/popover';
@import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/spinners';

// Helpers
@import 'node_modules/bootstrap/scss/helpers';

// Utilities
@import 'node_modules/bootstrap/scss/utilities/api';

/**
 * Custom imports
 */
@import 'type';
@import 'navs';
@import 'buttons';
@import 'structure';
@import 'primary';
@import 'discount';
@import 'forms';
@import 'background';
@import 'borders';
@import 'orders';
@import 'empty';
@import 'modals';
@import 'tooltip';
@import 'badges';
@import 'eco-financing';
@import 'breadcrumb';

/**
 * Styling "dev-tools" elements that are used strictly for local testing
 */
@import 'devtools';

/**
 * Styling third-party packages
 */
@import 'ngx-slider';
@import 'cms';
