// Checkbox
.form-check {
    input:not([disabled]) ~ label {
        cursor: pointer;
    }

    // If we want a disabled checkbox's label to not be faded
    .form-check-input.style-disabled[disabled],
    .form-check-input.style-disabled:disabled {
        & ~ .form-check-label {
            opacity: 1;
        }
    }
}

// Floating labels
.form-floating {
    label {
        color: $text-muted;
    }

    // If it's a dropdown, adjust input height to match text inputs
    .form-select {
        line-height: 1;
    }

    // If it's a dropdown, add padding-right to account for the chevron
    & > .form-select {
        padding-right: 2rem;
    }

    // Counteract the scale applied to the shrunk label
    & > .form-control ~ label,
    & > .form-select ~ label {
        // Ensure it is full-width of the input
        width: 133.33%;

        // Ensure x padding matches pre-scale size
        padding-left: $input-padding-x * 1.333;
        padding-right: $input-padding-x * 1.333;
    }
}

// Type number
// Remove the up/down ticker form number inputs
input[type='number'] {
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
}
.form-control:not(:placeholder-shown) {
    padding-top: 0.35rem !important;
}
