.modal-header {
    .btn-close {
        z-index: 1; // ensure it sits over content even if there is no title
    }
}

.modal-body {
    .modal-title {
        margin-bottom: map-get($spacers, 4);
    }
}

.compliance-callout {
    margin-left: -($modal-inner-padding);
    margin-right: -($modal-inner-padding);
}
