// Fallback variables in case unique variables are not set
// (Uses Enervee's color & font set)
$fallback-primary-color: 171, 89, 178;
$fallback-primary-hover-color: 198, 134, 204;
$fallback-primary-btn-text-color: 255, 255, 255;

$fallback-header-background-color: #ab59b2;
$fallback-header-hover-color: 198, 134, 204;
$fallback-header-color: 255, 255, 255;
$fallback-header-search-field-border-color: 255, 255, 255;

$fallback-discount-color: 255, 97, 81;
$fallback-discount-hover-color: 255, 97, 81;
$fallback-discount-btn-text-color: 255, 255, 255;

$fallback-link-color: 171, 89, 178;
$fallback-link-hover-color: 198, 134, 204;

$fallback-font-family-base: arial; // testing until fully implemented

// Unique variables set per utility, using values from site settings
$client-primary-color: RGB(var(--primary-color, $fallback-primary-color));
$client-primary-hover-color: RGB(
    var(--primary-hover-color, $fallback-primary-hover-color)
);
$client-primary-btn-text-color: RGB(
    var(--primary-btn-text-color, $fallback-primary-btn-text-color)
);

$client-header-background-color: var(
    --header-background-color,
    $fallback-header-background-color
);
$client-header-hover-color: RGB(
    var(--header-hover-color, $fallback-header-hover-color)
);
$client-header-color: RGB(var(--header-color, $fallback-header-color));
$client-header-search-field-border-color: RGB(
    var(
        --header-search-field-border-color,
        $fallback-header-search-field-border-color
    )
);

$client-discount-color: RGB(var(--discount-color, $fallback-discount-color));
$client-discount-hover-color: RGB(
    var(--discount-hover-color, $fallback-discount-hover-color)
);
$client-discount-btn-text-color: RGB(
    var(--discount-btn-text-color, $fallback-discount-btn-text-color)
);

$client-link-color: RGB(var(--link-color, $fallback-primary-color));
$client-link-hover-color: RGB(
    var(--link-hover-color, $fallback-primary-hover-color)
);

$client-font-family-base: var(--font-family, $fallback-font-family-base);

/**
 * From Bootstrap docs:
 *
 * Variables should follow the `$component-state-property-size` formula for
 * consistent naming.
 * Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 */

/**
 * Global color variables
 *
 * These are in-house variables to allow us fine-tuning of our designs.
 */

// Enervee Score
$score-color-grey: #868686;
$score-color-green: #72aa39;
$score-size: 40px;

// Star rating
$star-rating-color-grey: #dbdbdb;
$star-rating-color-yellow: #fdca58;

// Global border color to be used by all elements that need a border color
$global-border-color: #eeeaf0; // all borders should inherit this color
$global-input-border-color: #cbcbcb; // form element border color

// Global text colors to be used throughout the site
$global-body-color: #333333; // base text color
$global-text-muted: #868686; // muted text color

// Static component sizes
$header-mobile-size: 60px;
$header-desktop-size: 70px;
$cart-size: 360px;
$comparison-tray-size: 158px; // height of tray as it is currently
$call-to-action-size: 73px; // height of call-to-action as it is currently
$icon-height: 36px; // current height of a button
$icon-width: 160px; // product page iconography size

// Eco Financing
$financing-color: #ff6151; // Enervee red

// Sidebar
$sidebar-size-base: 383px; // product page, profile pages, site menu
$sidebar-size-sm: 279px; // filters sidebar

// Fort awesome
$fa-fw-size: 1.28571429em;
$fa-font-family: 'Webapp2V2';

/**
 * Bootstrap variable overrides
 *
 * NOTE:
 * Variables marked with !default are unchanged from their original value,
 * and are only present as a comparison for updated variables in the set,
 * or as a requirement for its partner value to work.
 *
 * Custom variables that go hand-in-hand with Bootstrap variables are also
 * listed here and marked appropriately to indicate they are custom variables.
 */

// Color map $theme-colors
$secondary: #cbcbcb; // grey button color
$success: #8cb95a;
$info: #36a3e3;
$warning: #ffbf5b;
$danger: #cf4b3a;
$light: #efefef; // background light
$dark: #000000;
$white: #ffffff;

// Thumbnail sizing
$thumbnail-sm: 50px; // product card mini
$thumbnail-lg: 160px; // product page, cart page

// Border color
$border-color: $global-border-color;
$input-border-color: $global-input-border-color;

// Type
$body-color: $global-body-color; // main text color
$text-muted: $global-text-muted; // light text color

$link-color: $client-link-color;
$link-hover-color: $client-link-hover-color;

$font-family-base: $client-font-family-base;
$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * 0.75; // 12px (based on 16px base)
$font-size-lg: $font-size-base * 2; // 32px (based on 16px base)

$font-size-display: $font-size-base * 2.125; // 34px

$small-font-size: 0.75em;
$large-font-size: 1.5em;

$h1-font-size: $font-size-base * 1.625; // 26px
$h2-font-size: $font-size-base * 1.375; // 22px
$h3-font-size: $font-size-base * 1.125; // 18px
$h4-font-size: $font-size-base; // 16px
$h5-font-size: $font-size-base * 0.875; // 14px
$h6-font-size: $font-size-base * 0.75; // 12px

$line-height-base: 1.4;
$line-height-sm: 1.2;

$headings-font-weight: 700;
$headings-line-height: $line-height-base;

// Button focus state
$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba(
    var(--primary-hover-color, $fallback-primary-hover-color),
    $input-btn-focus-color-opacity
);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width
    $input-btn-focus-color;

// Nav (tabs)
$nav-link-color: $link-color; // standard tab text color
$nav-link-hover-color: $link-hover-color; // standard tab hover color
$nav-link-disabled-color: $global-text-muted; // disabled tab text color

$nav-tabs-border-color: $global-border-color; // tab group bottom divider
$nav-tabs-link-hover-border-color: $global-border-color $global-border-color
    $nav-tabs-border-color; // standard tab border color
$nav-tabs-link-active-color: $global-body-color; // active tab text color
$nav-tabs-link-active-bg: $light;
$nav-tabs-link-active-border-color: $global-border-color $global-border-color
    $nav-tabs-link-active-bg; // active tab border

// Accordion
$accordion-padding-y: 16px;
$accordion-padding-x: 20px;
$accordion-bg: $light;
$accordion-border-color: $global-border-color;
$accordion-border-radius: 0px;
$accordion-inner-border-radius: 0px;
$accordion-button-color: $global-text-muted;
$accordion-button-bg: $light;
$accordion-button-active-bg: $light;
$accordion-button-active-color: $global-text-muted;
$accordion-button-focus-box-shadow: 0;
$accordion-icon-width: 1rem;
$accordion-icon-color: $global-text-muted;
$accordion-icon-active-color: $global-text-muted;

// Checkbox
$form-check-input-border: 1px solid $global-input-border-color;
$form-check-input-checked-color: $client-primary-btn-text-color;
$form-check-input-checked-bg-color: $client-primary-color;
$form-check-input-checked-border-color: $client-primary-color;
$form-check-input-focus-border: $client-primary-color;

// Form input
$input-border-color: $dark;
$input-disabled-border-color: $global-input-border-color;

// Form input focus state
$input-focus-border-color: rgba(
    var(--primary-hover-color, $fallback-primary-hover-color),
    0.5
);

// Form select
$form-select-border-color: $dark;
$form-select-disabled-color: $global-text-muted;
$form-select-disabled-border-color: $global-input-border-color;

// Form placeholder
$input-placeholder-color: $global-input-border-color;

// Table
$table-cell-vertical-align: middle;
$table-group-separator-color: $global-border-color;

// Close button
$btn-close-width: 0.75rem;
$btn-close-padding-x: 0.5rem;

$breadcrumb-divider: none;

// Required Bootstrap imports (RBi)
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/maps';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';

/**
 * Variables defined below must come after RBi
 */

// Pagination
$pagination-color: $client-primary-color;
$pagination-border-color: $global-border-color;
$pagination-focus-bg: $light;
$pagination-hover-color: $client-primary-hover-color;
$pagination-hover-bg: $body-bg;
$pagination-hover-border-color: $global-border-color;
$pagination-active-color: $client-primary-btn-text-color;
$pagination-active-bg: $client-primary-color;
$pagination-active-border-color: $client-primary-color;
$pagination-disabled-color: $global-text-muted;
$pagination-disabled-border-color: $global-border-color;

// Container
$container-padding-x: $grid-gutter-width;

// Horizontal rule
$hr-color: $global-border-color;
$hr-opacity: 1;
$hr-margin-y: map-get($spacers, 4);

// Form input floating labels
$form-floating-height: add(2.75rem, $input-height-border);
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 0.75rem;
$form-floating-input-padding-t: 1.35rem;
$form-floating-input-padding-b: 0.35rem;
$form-floating-label-opacity: 1;
$form-floating-label-transform: scale(0.75) translateY(-0.5rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;

// Badge
$badge-font-weight: $font-weight-normal;
$badge-color: $body-color;
$badge-border-radius: $border-radius-pill;

// Modal
$modal-inner-padding: map-get($spacers, 4);
$modal-content-border-width: 0;
$modal-header-padding: $modal-inner-padding $modal-inner-padding 0;
$modal-header-border-width: 0;
$modal-md: 400px;
$modal-lg: 560px;

// Z-index
$zindex-popover: $zindex-sticky - 1;

// Tooltip
$tooltip-background: $light;
