// Overriding elements that use $primary to use our custom variables
.bg-primary {
    background-color: $client-primary-color !important;
}

.btn-primary {
    background-color: $client-primary-color;
    border-color: $client-primary-color;
    color: $client-primary-btn-text-color;

    &:hover {
        background-color: $client-primary-hover-color;
        border-color: $client-primary-hover-color;
        color: $client-primary-btn-text-color;
    }
}

.btn-outline-primary {
    color: $client-primary-color;
    border-color: $client-primary-color;

    &:hover {
        color: $client-primary-btn-text-color;
        background-color: $client-primary-color;
    }
}

.text-primary {
    color: $client-primary-color !important;
}
