@mixin focus-state {
    &:focus {
        outline: 0;
        box-shadow: $input-btn-focus-box-shadow !important;
    }
}

@mixin focus-state-offset {
    &:focus {
        outline: 0.25rem solid $input-btn-focus-color;
        outline-offset: -(map-get($spacers, 2));
    }
}

@mixin score-size($scale: 1) {
    width: $score-size * $scale;
    height: $score-size * $scale;
    font-size: $score-size * $scale;
    line-height: $score-size * $scale;

    div[role='img'] {
        transform: scale($scale);
        transform-origin: 0 0;
    }
}
