// Variables specific to devtools
$devtools-red: #d41158;

.devtools {
    display: flex;
    align-items: stretch;
    font-family: Arial, Helvetica, sans-serif;
    color: $devtools-red;
    font-size: 10px;

    & > span {
        border-radius: 6px 0 0 6px;
        display: flex;
        align-items: center;
        background-color: $devtools-red;
        color: white;
    }

    .wrapper {
        border-radius: 0 6px 6px 0;
        border: 1px solid $devtools-red;
        background-color: lighten($devtools-red, 50);

        a,
        .btn-link {
            color: $devtools-red !important;
            font-size: inherit !important;
        }
    }
}
