@import 'variables';

// Bootstrap override to match .btn-link style
a {
    transition: color 0.25s ease-in-out;
    text-decoration: none;
    color: $link-color;
}

a:hover {
    color: $link-hover-color;
}
/**
 * In most situations, H1, H2, and H3 headers will have fixed margin-bottoms,
 * so let's hard-code them. Unique cases can still use `.mb-0` or otherwise to
 * override this.
 */
h1,
.h1,
h2,
.h2 {
    margin-bottom: map-get($spacers, 4);
}

h3,
.h3 {
    margin-bottom: map-get($spacers, 3);
}

/**
 * Bootstrap has a .small class which applies $small-font-size to the
 * font-size. Let's create a .large counterpart when we would like larger text.
 */
.large {
    font-size: $large-font-size;
}

// Used for very large texts such as product price on the product page
.fs-display {
    font-size: $font-size-display;
}
