@import 'mixins'; // focus-state

// Component-specific variables
$thumbnail-size: $score-size * 0.75;
$halo-size: 4px;
$bar-size: 8px;
$pointer-size: $bar-size;
$slider-spacing-y: 59px;

.custom-slider {
    .product-score-wrapper,
    .product-image-wrapper {
        margin: 0 ($score-size / 2);
        pointer-events: none; // allow click-through to slider pointer
    }

    .product-score-wrapper {
        product-score {
            margin-left: -($score-size / 2);
            z-index: 5;

            // Left/right arrows
            &:before,
            &:after {
                display: inline-block;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: $fa-font-family;
                font-weight: $font-weight-normal;
                position: absolute;
                top: 0;
                line-height: $score-size;
                font-size: $font-size-lg;
                color: $border-color;
            }

            &:before {
                content: '\f011';
                left: -($font-size-lg);
            }

            &:after {
                content: '\f010';
                right: -($font-size-lg);
            }
        }
    }

    .product-image-wrapper {
        z-index: 5; // sit above tick

        .content {
            height: $thumbnail-size;
            width: $thumbnail-size;
            margin-left: -($thumbnail-size) / 2;
        }

        .product-grey {
            border-color: $score-color-grey !important;
        }

        .product-green {
            border-color: $score-color-green !important;
        }
    }

    .slider-bar {
        padding: 0 ($score-size / 2);
        top: $slider-spacing-y;

        small {
            // Math: height of bar + height of product thumbnail that's visible below the bar + padding
            margin-top: calc(
                #{$bar-size} + (#{$score-size} - #{$bar-size}) / 2 + #{map-get(
                        $spacers,
                        2
                    )}
            );
        }

        .bar-grey,
        .bar-green {
            height: $bar-size;
        }

        .bar-grey {
            width: 50%;
            background-color: $score-color-grey;
            left: 0;
        }

        .bar-grey:nth-child(2) {
            width: 25%;
            left: 50%;
        }

        .bar-grey:nth-child(3) {
            width: 15%;
            left: 75%;
        }

        .bar-green {
            width: 10%;
            background-color: $score-color-green;
            right: 0;
        }
    }

    // Overriding out-of-the-box ngx-slider to fit our needs
    .ngx-slider {
        margin-top: $slider-spacing-y;
        margin-bottom: $slider-spacing-y;
        height: $bar-size;

        // Bar
        .ngx-slider-bar {
            height: $bar-size;
            background-color: transparent;
        }

        // Ticks
        .ngx-slider-ticks-values-under {
            top: 0;

            .ngx-slider-tick-value {
                font-size: $font-size-sm;
                color: $text-muted;
                // Math: half height of bar + height of product thumbnail that's visible below the bar + padding
                bottom: calc(
                    (#{$bar-size} / 2) + (#{$score-size} - #{$bar-size}) / 2 +
                        #{map-get($spacers, 2)}
                );
            }

            .ngx-slider-tick {
                $gap-width: $bar-size / 2;
                width: $gap-width;
                height: $bar-size;
                background-color: $body-bg;
                border-radius: 0;
                margin-left: ($score-size - $gap-width) / 2;
            }

            // Hide first and last tick to show bar rounded corners
            .ngx-slider-tick:first-child,
            .ngx-slider-tick:last-child {
                background-color: transparent;
            }
        }

        // Pointer
        .ngx-slider-pointer {
            top: -($slider-spacing-y);
            box-sizing: content-box;
            width: $score-size;
            height: $score-size;
            background-color: transparent;
            border-radius: $score-size / 2;

            @include focus-state;

            &:after {
                top: $slider-spacing-y - $halo-size;
                // MATH: size of score minus pointer with border
                left: ($score-size - ($pointer-size + $halo-size * 2)) / 2;

                box-sizing: content-box;
                width: $pointer-size;
                height: $pointer-size;
                border: $halo-size solid $body-bg;
                background-color: $score-color-grey;
                border-radius: ($pointer-size + $halo-size * 2) / 2;
            }

            &[aria-valuenow='100']:after,
            &[aria-valuenow^='9']:not([aria-valuenow='9']):after {
                background-color: $score-color-green;
            }

            &:hover:after,
            &.ngx-slider-active:after {
                background-color: $score-color-grey;
            }
        }

        // Hide current value
        .ngx-slider-model-value {
            display: none;
        }
    }
}
