// File-specific variables
// (Structure borrowed from BS)
$input-btn-discount-focus-color: rgba(
    var(--discount-hover-color, $fallback-discount-hover-color),
    $input-btn-focus-color-opacity
);
$input-btn-discount-focus-box-shadow: 0 0 0 $input-btn-focus-width
    $input-btn-discount-focus-color;
$btn-discount-focus-box-shadow: $input-btn-discount-focus-box-shadow;

// Defining discount element colors
// (Rebate, incentive, etc)
.bg-discount {
    background-color: $client-discount-color;
}

.btn-discount {
    background-color: $client-discount-color;
    border-color: $client-discount-color;
    color: $client-discount-btn-text-color;

    &:hover {
        background-color: $client-discount-hover-color;
        border-color: $client-discount-hover-color;
        color: $client-discount-btn-text-color;
    }

    &:focus {
        box-shadow: $btn-discount-focus-box-shadow;
    }
}

.text-discount {
    color: $client-discount-color;
}
