// Creating a dotted border style

.border,
.border-top {
    &.border-dotted {
        border-top-style: dotted !important;
    }
}

.border,
.border-bottom {
    &.border-dotted {
        border-bottom-style: dotted !important;
    }
}

.border,
.border-start {
    &.border-dotted {
        border-left-style: dotted !important;
    }
}

.border,
.border-end {
    &.border-dotted {
        border-right-style: dotted !important;
    }
}

// Creating a dashed border style

.border,
.border-top {
    &.border-dashed {
        border-top-style: dashed !important;
    }
}

.border,
.border-bottom {
    &.border-dashed {
        border-bottom-style: dashed !important;
    }
}

.border,
.border-start {
    &.border-dashed {
        border-left-style: dashed !important;
    }
}

.border,
.border-end {
    &.border-dashed {
        border-right-style: dashed !important;
    }
}

// Removing custom styling for subtractive borders

.border-top-0 {
    &.border-dotted,
    &.border-dashed {
        border-top-style: none !important;
    }
}

.border-bottom-0 {
    &.border-dotted,
    &.border-dashed {
        border-bottom-style: none !important;
    }
}

.border-start-0 {
    &.border-dotted,
    &.border-dashed {
        border-left-style: none !important;
    }
}

.border-end-0 {
    &.border-dotted,
    &.border-dashed {
        border-right-style: none !important;
    }
}
