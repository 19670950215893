// Move the last p tag's margin-bottom to the parent for easier
// Bootstrap class manipulation

cms-content {
    .p-group {
        margin-bottom: $paragraph-margin-bottom;

        p:last-child {
            margin-bottom: 0;
        }
    }
}
