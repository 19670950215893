@import 'variables';

// Creating light variants of the status colors
.bg-success-light {
    background-color: lighten($success, 40);
}

.bg-info-light {
    background-color: lighten($info, 40);
}

.bg-warning-light {
    background-color: lighten($warning, 25);
}

.bg-danger-light {
    background-color: lighten($danger, 40);
}

.img-overlay {
    background-color: rgba(0, 0, 0, 0.3);
}
